import React, { useState, useEffect, useRef } from 'react';
import { Col, Button, Row, Form, InputGroup, FloatingLabel, Tab, Nav, Modal } from "react-bootstrap";
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dummy_img from '../../../assets/images/dummy.png';
import paper_clip from '../../../assets/images/icons/paper_clip.png';
import search from '../../../assets/images/icons/search.png';
import member2 from '../../../assets/images/member2.png';

import firebaseDb from '../../../utils/firebase.util';
import { set, get, child, ref, push, update, onChildAdded, onChildChanged } from "firebase/database";

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST,PATCH } from '../../../utils/axios.util';
import { validateAndSortNumbers } from '../../../utils/functions';
// import useIsMounted from '../../../hooks/useIsMounted';
import io from "socket.io-client";
const socket = io(BACKEND_DOMAIN);
   
function SubAdminChat(props) {
    // const chatBoxRef = useRef(null);
    const socketRef = useRef(null);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const user = props.user
    const [allContacts, setAllContacts] = useState([])
    const [chatsList, setChatsList] = useState([])
    const [messagesList, setMessagesList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [otherUser, setOtherUser] = useState(null)
    const [isChatOpened, setIsChatOpened] = useState(false)
    const [chatMessage, setChatMessage] = useState(null)
    const [blockType, setBlockType] = useState('block')
    const [blockModalShow, setBlockModalShow] = useState(false)
    const [blockModalButtons, setBlockModalButtons] = useState(true)
    const [blockModalText, setBlockModalText] = useState('Are you sure you want to block this user ?')
    const bottomRef = useRef(null);
    const [chatUser, setChatUser] = useState(false)
    const [showChatUser, setTotalChatUser] = useState([])

    const usersListRef = useRef({})
    const chatsListRef = useRef([])
    const contactsInitialized = useRef(false)
    var anotherUserRef = useRef(null)
    const [ticketId, setTicketId] = useState(0)
    const [receiverId, setReceiverId] = useState(0)


    var usersList = usersListRef.current
    var allUsersList = []
    var keyword = ''

    useEffect(() => {
        if (!socketRef.current) {
          socketRef.current = io(BACKEND_DOMAIN);
        }
      
        const socket = socketRef.current;
      
        if (ticketId) {
          socket.emit("join_room", ticketId);
          socket.off("user_receive_message").on("user_receive_message", (data) => {
            setMessagesList((prev) => {
                console.log("received",data)
                const messageDate = moment().format("YYYY-MM-DD");
                const today = moment().format("YYYY-MM-DD");
                const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
            
                let dateLabel = moment(messageDate).format("MMMM D, YYYY"); // Default formatted date
            
                if (messageDate === today) {
                    dateLabel = "Today";
                } else if (messageDate === yesterday) {
                    dateLabel = "Yesterday";
                }
            
                let date = moment().format("YYYY-MM-DD HH:mm:ss")
                let chat_time_ = moment(date).format("HH:mm:ss");
                let chat_time = moment(chat_time_, "HH:mm") 
                    .format("HH:mm A");
                // New message object
                let newMessage = {
                    message: data.message,
                    role: data.role,
                    sender_id: data.sender_id,
                    receiver_id: data.receiver_id,
                    messagae_from : data.messagae_from,
                    created_at: messageDate,
                    contact_date: moment(messageDate).format("DD"),
                    contact_month: moment(messageDate).format("MMM"),
                    chat_time: chat_time,
                    message_from :data.message_from
                };
            
                let updatedMessagesList = [...prev];
                // let dateIndex = updatedMessagesList.findIndex(group => group.date_label === dateLabel);
            
                // if (dateIndex !== -1) {
                //     updatedMessagesList[dateIndex].messages.unshift(newMessage); // Add new message to the beginning
                // } else {
                //     updatedMessagesList.unshift({ date_label: dateLabel, messages: [newMessage] });
                // }
                let dateIndex = updatedMessagesList.findIndex(group => group.date_label === dateLabel);
            
                if (dateIndex !== -1) {
                    updatedMessagesList[dateIndex].messages.push(newMessage);
                } else {
                    updatedMessagesList.unshift({
                        date_label: dateLabel,
                        messages: [newMessage]
                    });
                }
                // Sort messages, but keep "Today" at the bottom
                updatedMessagesList.sort((a, b) => {
                    if (a.date_label === "Today") return 1;  // Move "Today" to the bottom
                    if (b.date_label === "Today") return -1; // Move "Today" to the bottom

                    let dateA = moment(a.date_label, ["MMMM D, YYYY", "Today", "Yesterday"]).format("YYYY-MM-DD");
                    let dateB = moment(b.date_label, ["MMMM D, YYYY", "Today", "Yesterday"]).format("YYYY-MM-DD");

                    return moment(dateB).diff(moment(dateA)); // Sort in descending order (newest first)
                });

                return updatedMessagesList;
            });
            
            // setMessagesList((prev) => [...prev, data]);
          });
        }
      
        return () => {
          if (!ticketId) {
            socket.disconnect();
            socketRef.current = null;
          }
        };
      }, [ticketId]);

      useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });

        if (!contactsInitialized.current) {
            getContacts(keyword)
        }

        return () => {
            let chatDiv = document.querySelector('#chat-contact')
            if (!chatDiv) {
                anotherUserRef.current = null
            }
        }

    }, [chatsList, messagesList, allContacts]);

    const textAreaChange = (event) => {
        event.stopPropagation();
        setChatMessage(event.target.value)
        setValue('chat', event.target.value)
    }

    // const renderChatList = (value) => {

    //     console.log('*****renderChatList*****')
    //     console.log(value,user)

    //     let usersArr = []
    //     let usersListArr = []
    //     let otherUsersArr = []
    //     let lastChat = null
    //     let newUsersListArr = []

    //     for(let key in value) {

    //             // if(anotherUserRef.current) {
    //                 // if((anotherUserRef?.current?.unique_id == value?.[key]?.['unique_id']) && (value?.[key]?.['is_seen'] == 0)) {
    //                 //     console.info("test123")
    //                 //     value[key]['is_seen'] = 1


    //                 //     const updates = {};
    //                 //     updates[`chats/user_${validateAndSortNumbers(anotherUserRef.current.unique_id,user.unique_id)}/${key}`] = {
    //                 //         ...value[key],
    //                 //         is_seen: 1,
    //                 //     };
    
    //                 //     update(ref(firebaseDb), updates);
    //                 // }

    //                 // if(user?.unique_id == value?.[key]?.['unique_id'] || user?.unique_id == value?.[key]?.['to_user_unique_id']){
    //                     if(value[key] !== 'support_ticketId'){   
    //                         otherUsersArr.push(value[key])
    //                     }
    //                 // }

    //         // }
    //         if(value[key] !== 'support_ticketId'){
    //             usersArr.push(value[key])
    //         }
    //     }

    //     if(usersArr.length > 0) {   
    //         lastChat = usersArr[usersArr.length - 1]
    //     }

    //     if(otherUsersArr.length > 0) {

    //         otherUsersArr.sort(function(a, b) {
    //             return Number(b.timestamp) - Number(a.timestamp)
    //         });

    //         otherUsersArr.sort(function(a, b) {
    //             return Number(a.is_seen) - Number(b.is_seen)
    //         });

    //         let usr = otherUsersArr?.find((i) => (i?.to_user_unique_id == user?.unique_id))
    //         if(!usr){
    //             const anotherusr = otherUsersArr?.find((i) => (i?.unique_id == user?.unique_id))
    //             if(anotherusr){
    //                 usr = anotherusr
    //             }
    //         }
    //         if(usr){
    //             // usr['message'] = lastChat['message']
    //             // usr['timestampFormatted'] = lastChat['timestampFormatted']
                
    //             usersList[usr['unique_id']] = usr
    //         }
                
    //             let chatUserIds = []
                
            
    //         if(Object.keys(usersList).length > 0) {
    //             for(let x in usersList) {
    //                 chatUserIds.push(usersList[x]['unique_id'])
    //                 usersListArr.push(usersList[x])

    //                 usersListArr.sort(function(a, b) {
    //                     return Number(b.timestamp) - Number(a.timestamp)
    //                 });
        
    //             }
    //         }

    //         if(usersListArr.length > 0 && allUsersList.length > 0) {
                
    //             let tempNonChatUsersList = []
    //             let tempChatUsersList = []
    //             for(let i = 0; i < allUsersList.length; i++) {
    //                 if(!chatUserIds.includes(allUsersList[i]['unique_id'])) {
    //                     tempNonChatUsersList.push(allUsersList[i])
    //                 }else {
                        
    //                     let filterChatUsr = usersListArr.filter(item => {
    //                         return ((item.unique_id == allUsersList[i]['unique_id']))
    //                     })

    //                     tempChatUsersList.push(filterChatUsr[0])
    //                 }
    //             }

    //             tempChatUsersList.sort(function(a, b) {
    //                 return Number(b.timestamp) - Number(a.timestamp)
    //             });

    //             newUsersListArr = [...tempChatUsersList, ...tempNonChatUsersList]

    //         }else {
    //             newUsersListArr = allUsersList
    //         }

    //     }else {
    //         newUsersListArr = allUsersList
    //     }

    //     chatsListRef.current = newUsersListArr
    //     // setChatsList(newUsersListArr)

    // }

    const renderMessagesList = (item) => {
        setValue('chat', '')
        console.log("anotherUserRef",anotherUserRef)
        if((anotherUserRef.current && (anotherUserRef.current.user_id == item.user_id) )) {
            setTicketId(item.unique_chat_id == 0 ? new Date().getTime() : item.unique_chat_id)
            setReceiverId(item.unique_chat_id === 0 ? item.user_id :
                 user.id === item.sender_id ? item.receiver_id : 
                 user.id === item.receiver_id ? item.sender_id : item.user_id)

            let payload = {
                url : `${BACKEND_DOMAIN}/admin/chat/message-list`,
                data: { unique_chat_id: item.unique_chat_id }
            }
    
            PATCH(payload).then(res => {
                if(res.status){
                    setIsChatOpened(true)
                    setOtherUser(item)
                    setMessagesList(res.data.data)
                }else{
                    setIsChatOpened(true)
                    setOtherUser(item)
                    setMessagesList(res.data.data)
                }
            }).catch(err => {
                console.log(err)
    
                // Session Logout Code
                if(err.response && err.response.status === 401) {
                    toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                }
                
            })
        }

    }
    console.log("hiii",receiverId)
    const getContacts = (keyword) => {

        let payload = {
            url: `${BACKEND_DOMAIN}/admin/chat/organisation/user?search=${keyword}`
        }

        setIsLoading(true)
        GET(payload).then(res => {
            const { data ,chatUser } = res.data

            allUsersList = data
            setChatUser(chatUser.length > 0 || keyword ? true : false);
            setTotalChatUser(chatUser)
            contactsInitialized.current = true
            setIsLoading(false)
            setAllContacts(data)
            if (keyword && keyword.trim() !== "") {
                setChatsList([...chatUser, ...data]); // Merge arrays
            } else {
                setChatsList(chatUser); // Keep only chatUser
            }
            // getChats()

        }).catch(err => {

            contactsInitialized.current = true
            setIsLoading(false)
            console.log(err)

        })

    }

    // const getChats = async () => {

    //     const chatListRef = ref(firebaseDb, `chats`);

    //     onChildAdded(chatListRef, (data) => {

    //         console.log('*******onChildAdded()*******')

    //         let value = data.val()
    //         if (anotherUserRef.current) {
    //             renderMessagesList(anotherUserRef.current)
    //         } else {

    //             renderChatList(value)
    //         }

    //     });

    //     onChildChanged(chatListRef, (data) => {

    //         console.log('*******onChildChanged()*******')

    //         if (anotherUserRef.current) {

    //             let item = anotherUserRef.current
    //             renderMessagesList(item)
    //         } else {

    //             let value = data.val()
    //             let messageList = []

    //             for (let msgKey in value) {
    //                 messageList.push(value[msgKey])
    //             }
    //             const filterData = messageList?.filter((item) => item?.type !== "support")
    //             setMessagesList(filterData)
    //             renderChatList(value)

    //         }

    //     });

    // }

    const openChat = (item) => {
        setMessagesList([])
        anotherUserRef.current = item
        renderMessagesList(item)
    }

    const clearMessagesList = (t_id) => {

        let payload = {
            url: `${BACKEND_DOMAIN}/admin/chat/messages/delete`,
            data: {
                t_id
            }
        }

        POST(payload).then(res => {

            setMessagesList([]);

        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const openBlockModal = (type) => {

        let text = 'Are you sure you want to block this user ?'
        setBlockType('block')

        if (type == 'unblock') {
            text = 'Are you sure you want to unblock this user ?'
            setBlockType('unblock')
        }

        setBlockModalButtons(true)
        setBlockModalText(text)
        setBlockModalShow(true)
    }

    const blockUser = () => {

        let URL = `${BACKEND_DOMAIN}/admin/chat/user-block`
        if (blockType == 'unblock') {
            URL = `${BACKEND_DOMAIN}/admin/chat/unblock`
        }

        let payload = {
            url: URL,
            data: {
                user_id: receiverId,
                ticket_id: ticketId
            }
        }

        POST(payload).then(res => {

            let newotheruser = {}

            if (blockType == 'unblock') {
                newotheruser = {
                    ...otherUser,
                    is_chat_blocked: 0
                }
                anotherUserRef.current = newotheruser
            } else {
                newotheruser = {
                    ...otherUser,
                    is_chat_blocked: 1
                }
                anotherUserRef.current = newotheruser
            }

            chatsList.map(item => {
                if (item.user_id == otherUser.user_id) {
                    if (blockType == 'unblock') {
                        item['is_chat_blocked'] = 0
                    } else {
                        item['is_chat_blocked'] = 1
                    }

                }
            })

            setChatsList(chatsList)
            setOtherUser(newotheruser)

            setTimeout(() => {
                setBlockModalShow(false)
            }, 2000)

            setBlockModalButtons(false);
            setBlockModalText(res.data.message);

        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const onSubmit = (data) => {
        
        let formData = data
        let chatData = {
            sender_id: user.id,
            receiver_id: receiverId,
            room_id: ticketId,
            role: user.role,
            message: data.chat,
        }

        let payload = {
            url : `${BACKEND_DOMAIN}/admin/chat/send-message`,
            data:chatData
        }

        PATCH(payload).then(res => {
            if(res.status){
                socket.emit("user_send_message",
                    { 
                    message: data.chat,
                    room_id: ticketId ,
                    sender_id: user.id,
                    receiver_id: anotherUserRef.current.user_id,
                    role: user.role,
                    preMessage: messagesList
                });

                setValue('chat', '')
                // setChatMessage('')
            }else{
            }
        }).catch(err => {
            console.log(err)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            
        })

    }

    return (
        <>
            <h2 className="page_hdng">Chats</h2>
            <div className='my_card chat_contact mt-4' id="chat-contact">
                <Row>
                    <Col sm="12" lg="5">
                        <div className="my_card_brand_dark_color">
                            <InputGroup className="chat_list_seach">
                                <Button variant="outline-secondary" id="">
                                    <img width="15" src={search} />
                                </Button>
                                <Form.Control size="sm" placeholder="Search" onChange={(e) => getContacts(e.target.value)} autoComplete="off" />
                            </InputGroup>
                            <Tab.Container className="" defaultActiveKey="chats">
                                <Nav fill justify variant="brand_outline_pills" className="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="chats">Chat</Nav.Link>
                                    </Nav.Item>
                                    {/*  <Nav.Item>
                                        <Nav.Link eventKey="contacts">Contacts</Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="chats">
                                        {isLoading &&
                                            <div className="contact_list" >
                                                <Row className='align-items-center'>
                                                    <h5 className='fs-16 fw-600 text-center'>Loading...</h5>
                                                </Row>
                                            </div>
                                        }
                                        {!isLoading && chatsList.length === 0 && chatUser &&
                                            <div className="contact_list" >
                                                <Row className='align-items-center'>
                                                    <h5 className='fs-16 fw-600 text-center'>No Records Found!</h5>
                                                </Row>
                                            </div>
                                        }
                                        {!isLoading && chatsList.length > 0 && chatUser &&
                                            chatsList.map((elem, index) => {

                                                let textClass = 'text_light_color2'

                                                let classNames = 'contact_list'

                                                if (elem.is_seen === 0) {
                                                    textClass = 'text_brand_color2'
                                                }

                                                if (anotherUserRef.current && elem.user_id == anotherUserRef.current.user_id) {
                                                    classNames = 'contact_list active'
                                                }

                                                return (
                                                    <div className={classNames} key={elem.user_id} style={{ cursor: 'pointer' }} onClick={() => openChat(elem)} >
                                                        <Row className='align-items-center justify-content-between'>
                                                            <Col className='col-auto'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div>
                                                                        <img width="50" className="rounded-5" src={elem.sender_id === user.id ? elem.receiver_picture_path :elem.picture_path} />
                                                                    </div>
                                                                    <div className="ms-2">
                                                                        <h4 className='fs-16 fw-600'>
                                                                            {elem.sender_id === user.id ? elem.full_name : elem?.sender_full_name || elem?.full_name}
                                                                        </h4>
                                                                        <p className='text_light_color2 fs-14 fw-400 m-0'>{elem.message}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col className='col-auto'>
                                                                <div className='text-end'>
                                                                    <p className='text_light_color2 fs-12 fw-500 mb-2 text-capitalize'>{elem.role}</p>
                                                                    {elem.timestampFormatted &&
                                                                        <p className={`${textClass} fw-14 fw-500 m-0`}>{moment(elem.timestampFormatted).fromNow()}</p>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            <div className="chat_list_"></div>
                        </div>
                    </Col>
                    <Col sm="12" lg="7">
                        <div className="my_card_brand_dark_color">
                            {isChatOpened &&

                                <>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='chat_active_uesr'>
                                            <img src={anotherUserRef.current.sender_id === user.id ? anotherUserRef.current.receiver_picture_path :anotherUserRef.current.picture_path} />
                                            <h5 className='fs-20 fw-600 m-0'>
                                                {anotherUserRef.current.sender_id === user.id ? anotherUserRef.current.full_name : anotherUserRef.current?.sender_full_name || anotherUserRef.current?.full_name}
                                            </h5>
                                        </div>
                                        <div>
                                            <Button variant="danger" className="mx-2" onClick={() => clearMessagesList(ticketId)} >Clear All</Button>
                                            {otherUser.is_chat_blocked === 0 &&
                                                <Button variant="primary" onClick={() => openBlockModal('block')} >Block</Button>
                                            }
                                            {otherUser.is_chat_blocked === 1 &&
                                                <Button variant="primary" onClick={() => openBlockModal('unblock')} >Unblock</Button>
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                </>
                            }

                            <div className='chat_msg_container'>
                                {isLoading &&
                                    <div className='text-center subscriptions_packages_name no_records_loading'>
                                        <h5>Loading...</h5>
                                    </div>
                                }
                                {!isChatOpened && !isLoading &&
                                    <div className='d-flex justify-content-center'>
                                        <h5>            </h5>
                                    </div>
                                }
                                {isChatOpened &&
                                    <>
                                    {/* {messagesList.length > 0 &&

                                                messagesList.map((elem, index) => {

                                                    if (!elem.message || elem.message?.length < 1) {
                                                        return
                                                    }

                                                    let classValue = 'chat_msg_list_item msg_me'

                                                    if (elem?.sender_id !== user?.id) {
                                                        classValue = 'chat_msg_list_item msg_other'
                                                    }

                                                    return (
                                                        <li className={classValue} key={elem.key}>
                                                            <div className='chat_msg'>{elem.message}
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            } */}
                                        <ul className='chat_msg_list' key={Math.random()} style={{ overflowY: "auto", maxHeight: "400px" }}>
                                            {messagesList.length > 0 && messagesList.map((group, index) => (
                                                <div key={index}> 
                                                    <div className="chat_date_container">
                                                        <div className="chat_date_label">{group.date_label}</div>
                                                    </div>

                                                    {group.messages.map((elem) => {
                                                        if (!elem.message || elem.message.length < 1) {
                                                            return null; 
                                                        }
                                                        let classValue = 'chat_msg_list_item msg_other';
                                                        console.log("id check",elem?.sender_id , user?.id ,elem.message_from)
                                                        if ((elem?.sender_id === user?.id && elem.message_from === 'sender') || (elem?.receiver_id === user?.id && elem.message_from === 'receiver')) {
                                                            classValue = 'chat_msg_list_item msg_me';
                                                        }

                                                        return (
                                                            <li className={classValue} key={elem.id}>
                                                                <div className='chat_msg'>
                                                                    {elem.message}
                                                                    <span className="chat_time">{elem.chat_time}</span>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </div>
                                            ))}

                                            <div ref={bottomRef} />
                                        </ul>
                                        <FloatingLabel controlId="floatingTextarea" className="chat_textarea" >
                                            <Form onSubmit={handleSubmit(onSubmit)} >
                                                {/* <input type="hidden" name="other_user" value={anotherUserRef.current && anotherUserRef.current.user_id} {...register('other_user')} /> */}
                                                <Form.Control as="textarea" placeholder="Type something" name="chat" {...register('chat')} onChange={(event) => textAreaChange(event)} autoComplete="off" />
                                                <div className='chat_textarea_buttons'>
                                                    {/* <div className="upload_btn_wrapper">
                                                        <button className="attach_btn">
                                                            <img height="15" src={paper_clip} />
                                                            <span className='ms-2'>Attach</span>
                                                        </button>
                                                        <Form.Control type="file" name="myfile" />
                                                    </div> */}
                                                    <Button disabled={!chatMessage} variant='success' type="submit">Send</Button>
                                                </div>
                                            </Form>
                                        </FloatingLabel>
                                    </>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal className="approve-modal" show={blockModalShow} onHide={() => setBlockModalShow(false)} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>{blockModalText}</h4>
                </Modal.Body>
                {blockModalButtons &&
                    <Modal.Footer className='justify-content-center'>
                        <div className="d-flex gap-3">
                            <Button variant="danger" onClick={() => setBlockModalShow(false)} >NO</Button>
                            <Button variant="primary" onClick={() => blockUser()}>YES</Button>
                        </div>
                    </Modal.Footer>
                }
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    return {
        user: state.authenticate
    }
}

export default connect(mapStateToProps, null)(SubAdminChat);