import React, {useRef, useEffect, useState } from 'react';
import { Col, Button, Row, Modal} from "react-bootstrap";
import { NavLink ,useParams,useLocation,useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';
import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET ,POST} from '../../../utils/axios.util';

import SchoolSubscriptionsPlanCtrl from './Plan';

function SchoolSubscriptionsCtrl(props){
    var location = useLocation();
 
    const navigate = useNavigate();
    const isRedirected = useRef(false); // Track if redirection has occurred

   
    const params = useParams();
    const [ currentPlan, setCurrentPlan ] = useState({})
    const [ plan, setPlan ] = useState(params.plan)
    const [ subscriptionPlans, setSubscriptionPlans ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)
    const [ planType, setPlanType ] = useState('monthly')
    const [ticketModalShow, setTicketModalShow] = useState(false);
   
    useEffect(() => {

        if(!subscriptionPlans.length > 0) {
            getSubscriptionPlans()
        }
        
    },[]);


    const getSubscriptionPlans = () => {

        setIsLoading(true)

        let payload = {
            url : `${BACKEND_DOMAIN}/subscriptions`
        }
        GET(payload).then(res => {
            if(res.data) { 
                setSubscriptionPlans(res.data.data)
                
                setIsNoRecords(false)
                setIsLoading(false)
            }
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    useEffect (() =>{
        var planFilter = subscriptionPlans.filter(pln => {
            return params.plan === pln.name.toLowerCase()
        }) 
        if(location.pathname == "/school/subscriptions") {
            planFilter[0] = subscriptionPlans[0]
        }
        setCurrentPlan(planFilter[0])
    },[ currentPlan, params.plan ,subscriptionPlans]);

    
    const changePlanType = (type) => {
        setPlanType(type)
    }

    const openPlanModal = () => {
        
        if(subscriptionPlans.length > 0){
            let currentPlans = subscriptionPlans[0]?.['current_subscription']?.['name']; 
            let selectedPlan = currentPlan?.name; 
            // Define the upgrade order
            const planOrder = ["Basic", "Professional", "Enterprise"];

            // Get index positions in the planOrder array
            let currentIndex = planOrder.indexOf(currentPlans);
            let selectedIndex = planOrder.indexOf(selectedPlan);

            if (currentIndex === selectedIndex) {
                toast.error("You have already selected this plan. Please upgrade to a higher plan.");
            } else if (selectedIndex < currentIndex) {
                toast.error("Downgrading is not allowed. Please select a higher plan.");
            } else {
                setTicketModalShow(true);
            }

        }
    }
    const handleUpgradeSubscription = () =>{
           let URL = `${BACKEND_DOMAIN}/subscriptions/upgrade`

        let payload = {
            url: URL,
            data: {
                subscriptionPlan: JSON.stringify(currentPlan),
            }
        }

        POST(payload).then(res => {
            if(res.data.status){
                toast.success(res.data.message)
                getSubscriptionPlans()
            }else{
                toast.error("Not upgraded subscriptions.")
            }
            
            setTimeout(() => {
                setTicketModalShow(false)
            }, 1500)
        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }
    const handleTicketModalClose = () => {
        setTicketModalShow(false)
       
    }

    useEffect(() => {
        if (!isRedirected.current && subscriptionPlans.length > 0) {
            let currentPlanName = subscriptionPlans[0]?.current_subscription?.name.toLowerCase();

            // If the URL plan does not match the actual current plan, update it
            if (params.plan !== currentPlanName) {
                navigate(`/school/subscriptions/${currentPlanName}`, { replace: true });
                isRedirected.current = true; // Mark redirection as done
            }
        }
    }, [subscriptionPlans, navigate]); // Removed params.plan to avoid re-triggering

    return(
        <>        

            <h2 className="page_hdng">Subscriptions</h2>
            { (isNoRecords === false) &&  subscriptionPlans.length > 0 &&
                <div className="my_card_brand_color3 mt-3 p-3">
                    <Row className="basic_subscription justify-content-center align-items-center">
                        <Col>
                            <div className="d-flex align-items-center">
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="4" cy="4" r="4" fill="#F2F1FF"/>
                                        <circle opacity="0.8" cx="4" cy="12" r="4" fill="#F2F1FF"/>
                                        <circle opacity="0.6" cx="4" cy="20" r="4" fill="#F2F1FF"/>
                                        <circle opacity="0.4" cx="12" cy="20" r="4" fill="#F2F1FF"/>
                                        <circle cx="20" cy="20" r="4" fill="#F2F1FF"/>
                                        <circle cx="12" cy="12" r="4" fill="#F2F1FF"/>
                                    </svg>
                                </span>
                                <h5 className="mb-0 ms-3">Current Subscription</h5>&nbsp;
                                <button className="change_plan_btn" >{subscriptionPlans[0]?.['current_subscription']?.['name']}</button>
                                &nbsp;&nbsp;
                                <button className="change_plan_btn">
                                    Your plan will expire on {moment(subscriptionPlans[0]['current_subscription']['end_ts']).format('MMMM D, YYYY')}
                                </button>
                            </div>
                        </Col>
                        {/* <Col className="col-auto">
                            <button className="change_plan_btn" >Change plan</button>
                        </Col> */}
                    </Row>
                </div>
            }
            <Row>
                <Col>
                    { (isNoRecords === false) &&  subscriptionPlans.length > 0 &&
                        <div className='my_card mt-3'>
                            <Row>
                                <Col sm="12" md="6" lg="6">
                                    <div className='my_card_brand_dark_color h-100'>
                                        <SchoolSubscriptionsPlanCtrl onChangePlanType={changePlanType} />
                                    </div>
                                </Col>
                                <Col sm="12" md="6" lg="6" className='subscriptions_packages plans'>

                                {   subscriptionPlans.map((elem, index) => {
                                    
                                    let price = 0
                                    let planText = ''
                                    /* if(planType === 'monthly') {
                                        price = elem.monthly_amount
                                    }else {
                                        price = elem.yearly_amount
                                    } */

                                    if(elem.monthly_amount > 0) {
                                        price = elem.monthly_amount
                                        planText = 'monthly'
                                    }else {
                                        price = elem.yearly_amount
                                        planText = 'yearly'
                                    }

                                    return (
                                        <div key={elem.id}>
                                            <NavLink 
                                                to={elem.name.toLowerCase()} 
                                                isActive={() => subscriptionPlans[0]?.current_subscription?.name?.toLowerCase() === elem.name.toLowerCase()}
                                            >
                                                <div className={`packages_container 
                                                    ${subscriptionPlans[0]?.current_subscription?.name?.toLowerCase() === elem.name.toLowerCase() ? 'active-plan' : ''}`}>
                                                    <div className='subscriptions_packages_name'>
                                                        <h5>{elem.name}</h5>
                                                        {elem.discount && <Button className="change_plan_btn discount">Save {elem.discount}%</Button>}
                                                    </div>
                                                    <h4>${price}<sub>/<span className="text-capitalize">{planText}</span></sub></h4>
                                                </div>
                                            </NavLink>
                                        </div>
                                    )

                                    })
                                }

                                </Col>
                            </Row>
                            <hr/>
                            <div className='text-end'>
                                <Button variant='success' size='lg' onClick={openPlanModal}>Choose plan</Button>
                            </div>
                        </div>
                    }
                    { isNoRecords && 
                        <div className='text-center subscriptions_packages_name no_records_loading' >
                            <h5>No Records Found!</h5>
                        </div>
                    }
                    { isLoading && 
                        <div className='text-center subscriptions_packages_name no_records_loading'>
                            <h5>Loading...</h5>
                        </div>
                    }
                </Col>
            </Row>
            <Modal className="approve-modal custom-modal" show={ticketModalShow} onHide={handleTicketModalClose} backdrop="static" keyboard={false} centered>
                    <Modal.Header className='justify-content-center'>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <div className="d-flex gap-3">
                            <Col sm="12" md="12" lg="12" className="d-flex align-items-stretch">
                                <div className="my_card_brand_dark_color w-100 h-100 d-flex">
                                    <SchoolSubscriptionsPlanCtrl onChangePlanType={changePlanType} />
                                </div>
                            </Col>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                            <Button variant="danger" onClick={handleTicketModalClose} >NO</Button>
                            <Button variant="primary" onClick={handleUpgradeSubscription} >YES</Button>
                    </Modal.Footer>
            </Modal>
        </>
    )
}

export default SchoolSubscriptionsCtrl;